<template>
  <div role="main">
    <br>
    <br>
    <button type="button" class="button green" @click="$router.push({name: 'apps-academic-writing'})">Writing Test</button>
    <button type="button" class="button green" @click="$router.push({name: 'apps-academic-reading'})">Reading Test</button>
    <button type="button" class="button green" @click="$router.push({name: 'apps-academic-listening'})">Listening Test</button>

  </div>
</template>

<script>

export default {
  name: "TakeTest",
  components: {}
}
</script>

<style scoped>

</style>
